declare var inIframe: any;

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { APP_CONSTANTS, LANGUAGES, SVG_ICONS, WIDGET_TYPE_CONSTANTS } from '@constants/app.constants';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { PartnerService } from '@services/feature-service/partner.service';
import { LoggerService } from '@services/util-service/logger.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  protected window: Window;
  protected readonly languages = LANGUAGES;
  protected readonly widgetTypeConstants = WIDGET_TYPE_CONSTANTS;
  protected readonly dashboardUrl = environment.dashboardUrl;
  private readonly svgIcons = SVG_ICONS;
  private readonly deployUrl = environment.deployUrl;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: Document,
    private partnerService: PartnerService,
    private route: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.window = this.document?.defaultView
    }
  }

  /**
   * Returns users default browser language or default language of the widget if the brower language cannot be resolved
   */
  getDefaultLanguage(queryStringLang: string = null): string {
    const partnerSupportedLang = this.partnerService?.partner?.supported_widget_languages || [APP_CONSTANTS.DEFAULT_LANG.split('_')[0]];
    const partnerDefaultLang = this.partnerService?.partner?.language?.identifier || APP_CONSTANTS.DEFAULT_LANG;
    const browserlang = this.translate.getBrowserCultureLang() || '';
    let languageTobeSet = undefined;

    if (queryStringLang) {
      for (var i = 0; i < partnerSupportedLang.length; i++) {
        const language = partnerSupportedLang[i];
        if (queryStringLang.includes(language.toLowerCase())) {
          languageTobeSet = language;
          break;
        }
      }
    }

    if (!languageTobeSet) {
      for (var i = 0; i < partnerSupportedLang.length; i++) {
        const language = partnerSupportedLang[i];
        if (browserlang.includes(language.toLowerCase())) {
          languageTobeSet = language;
          break;
        }
      }
    }

    !languageTobeSet && (languageTobeSet = partnerDefaultLang.split('_')[0]);

    const langObj = this.languages.find(languages => languages.value === languageTobeSet);
    return langObj.locale || partnerDefaultLang;
  }

  /*
   * Get passed key values from given object
   */
  getQueryParamValues(urlparams: any, key1: string, key2: string): any {
    if (urlparams[key1]) {
      return urlparams[key1];
    } else if (urlparams[key2]) {
      return urlparams[key2];
    } else {
      return urlparams[key1];
    }
  }

  getQueryStringKey(key: string, query: any, isArray: boolean): any {
    const value = this.getQueryStringAsObject(query)[key];

    if (value && value?.length !== undefined) { delete value.length; }

    if (value && isArray) {
      return Object.keys(value).map(function (_) {
        return value[_];
      });
    }

    return value;
  }

  getQueryStringAsObject(query: any): any {
    var b, cv, e, k, ma, sk, v, r = {},
      d = function (v) {
        return decodeURIComponent(v).replace(/\+/g, ' ');
      }, // d(ecode) the v(alue)
      q = query, // suggested: q = decodeURIComponent(window.location.search.substring(1)),
      s = /([^&;=]+)=?([^&;]*)/g // original regex that does not allow for ; as a delimiter:   /([^&=]+)=?([^&]*)/g
    ;

    // ma(make array) out of the v(alue)
    ma = function (v) {
      // If the passed v(alue) hasn't been setup as an object
      if (typeof v != 'object') {
        // Grab the cv(current value) then setup the v(alue) as an object
        cv = v;
        v = {};
        v.length = 0;

        // If there was a cv(current value), .push it into the new v(alue)'s array
        //     NOTE: This may or may not be 100% logical to do... but it's better than loosing the original value
        if (cv) {
          Array.prototype.push.call(v, cv);
        }
      }
      return v;
    };

    // While we still have key-value e(ntries) from the q(uerystring) via the s(earch regex)...
    while (e = s.exec(q)) { // while((e = s.exec(q)) !== null) {
      // Collect the open b(racket) location (if any) then set the d(ecoded) v(alue) from the above split key-value e(ntry)
      b = e[1].indexOf('[');
      v = d(e[2]);

      // As long as this is NOT a hash[]-style key-value e(ntry)
      if (b < 0) { // b == "-1"
        // d(ecode) the simple k(ey)
        k = d(e[1]);

        // If the k(ey) already exists
        if (r[k]) {
          // ma(make array) out of the k(ey) then .push the v(alue) into the k(ey)'s array in the r(eturn value)
          r[k] = ma(r[k]);
          Array.prototype.push.call(r[k], v);
        }
        // Else this is a new k(ey), so just add the k(ey)/v(alue) into the r(eturn value)
        else {
          r[k] = v;
        }
      }
      // Else we've got ourselves a hash[]-style key-value e(ntry)
      else {
        // Collect the d(ecoded) k(ey) and the d(ecoded) sk(sub-key) based on the b(racket) locations
        k = d(e[1].slice(0, b));
        sk = d(e[1].slice(b + 1, e[1].indexOf(']', b)));

        // ma(make array) out of the k(ey)
        r[k] = ma(r[k]);

        // If we have a sk(sub-key), plug the v(alue) into it
        if (sk) {
          r[k][sk] = v;
        }
        // Else .push the v(alue) into the k(ey)'s array
        else {
          Array.prototype.push.call(r[k], v);
        }
      }
    }

    // # Return the r(eturn value)
    return r;
  }

  /*
  * Returns final set of widget options that can be configured using query params
  */
  getWidgetQueryParams(): { [key: string]: any } {
    const finalParams: { [key: string]: any } = {};
    const urlparams: {[key: string]: any} = {};
    const queryString = (isPlatformBrowser(this.platformId)) ? decodeURIComponent(this?.window?.location?.search?.substring(1)) : '';

    this.route.queryParamMap.subscribe(params => {
      for (const param of params.keys) {
        let value = params.get(param);
        value = (value === 'NULL' || value === 'null' || value === 'undefined' || value === 'UNDEFINED') ? undefined : value;
        urlparams[param] = value;
      }
    });

    // type
    if (urlparams?.type) {
      finalParams['type'] = [
        this.widgetTypeConstants.APPOINTMENTS,
        this.widgetTypeConstants.EVENTS,
        this.widgetTypeConstants.COMPACT
      ].includes(urlparams?.type?.toLowerCase()) ? urlparams?.type?.toLowerCase() : this.widgetTypeConstants.APPOINTMENTS;
    }

    // q
    if (urlparams?.q) { finalParams['q'] = urlparams.q; }

    // debug
    if (urlparams?.debug === "true") { finalParams['debug'] = true; }

    // internal
    if (urlparams?.internal === "true") { finalParams['internal'] = true; }

    // token
    if (urlparams?.token) { finalParams['token'] = urlparams?.token; }

    // event id
    const eventId = this.getQueryParamValues(urlparams, 'event-id', 'event_id');
    eventId && (finalParams['event-id'] = eventId);

    // skip-qualification-question
    const skipQualificationQuestion = this.getQueryParamValues(urlparams, 'skip-qualification-question', 'skip_qualification_question');
    skipQualificationQuestion && (finalParams['skip-qualification-question'] = skipQualificationQuestion);

    // Date context
    const date = this.getQueryParamValues(urlparams, 'date', 'date');
    date && (finalParams['date'] = date);

    // Date context
    const dateContext = this.getQueryParamValues(urlparams, 'date_context', 'date-context');
    dateContext && (finalParams['date-context'] = dateContext);

    // Date context start
    const dateContextStart = this.getQueryParamValues(urlparams, 'date_context_start', 'date-context-start');
    dateContextStart && (finalParams['date-context-start'] = dateContextStart);

    // Date context end
    const dateContextEnd = this.getQueryParamValues(urlparams, 'date_context_end', 'date-context-end');
    dateContextEnd && (finalParams['date-context-end'] = dateContextEnd);

    // worker uuid
    const workerUuid = this.getQueryParamValues(urlparams, 'worker-uuid', 'worker_uuid');
    workerUuid && (finalParams['worker-uuid'] = workerUuid);

    // meeting types
    const meetingTypeId = this.getQueryParamValues(urlparams, 'meeting-type-id', 'meeting_type_id');
    meetingTypeId && (finalParams['meeting-type-id'] = meetingTypeId);

    // customer notification preference
    const customerNotificationPreference = this.getQueryParamValues(urlparams, 'customer-notification-preference', 'customer_notification_preference');
    customerNotificationPreference && (finalParams['customer-notification-preference'] = customerNotificationPreference);

    // utm source
    const utmSource = this.getQueryParamValues(urlparams, 'utm_source', 'utm-source');
    utmSource && (finalParams['utm-source'] = utmSource);

    // utm medium
    const utmMedium = this.getQueryParamValues(urlparams, 'utm_medium', 'utm-medium');
    utmMedium && (finalParams['utm-medium'] = utmMedium);

    // utm content
    const utmContent = this.getQueryParamValues(urlparams, 'utm_content', 'utm-content');
    utmContent && (finalParams['utm-content'] = utmContent);

    // utm campaign
    const utmCampaign = this.getQueryParamValues(urlparams, 'utm_campaign', 'utm-campaign');
    utmCampaign && (finalParams['utm-campaign'] = utmCampaign);

    // utm term
    const utmTerm = this.getQueryParamValues(urlparams, 'utm_term', 'utm-term');
    utmTerm && (finalParams['utm-term'] = utmTerm);

    // booker worker uuid
    const bookerWorkerId = this.getQueryParamValues(urlparams, 'booker-worker-id', 'booker_worker_id');
    bookerWorkerId && (finalParams['booker-worker-id'] = bookerWorkerId);

    // grid switcher
    const hideGridSwitcher = this. getQueryParamValues(urlparams, 'hide-grid-switcher', 'hide_grid_switcher');
    hideGridSwitcher && (finalParams['hide-grid-switcher'] = hideGridSwitcher);

    // store id
    const selectedStoreId = this.getQueryParamValues(urlparams, 'store_id', 'store-id');
    selectedStoreId && (finalParams['selected-store-id'] = selectedStoreId);

    // store zip
    const selectedStoreZipCode = this.getQueryParamValues(urlparams, 'store-zip', 'store_zip');
    selectedStoreZipCode && (finalParams['selected-store-zip'] = selectedStoreZipCode);

    const selectedStoreName = this.getQueryParamValues(urlparams, 'store_name', 'store-name');
    selectedStoreName && (finalParams['selected-store-name'] = decodeURIComponent(selectedStoreName));

    // worker id
    const selectedWorkerId = this.getQueryParamValues(urlparams, 'worker_id', 'worker-id');
    selectedWorkerId && (finalParams['selected-worker-id'] = selectedWorkerId);

    // worker email
    const selectedWorkerEmail = this.getQueryParamValues(urlparams, 'worker_email', 'worker-email');
    selectedWorkerEmail && (finalParams['selected-worker-email'] = selectedWorkerEmail);

    // internal comment
    const internalComment = this.getQueryParamValues(urlparams, 'internal_comment', 'internal-comment');
    internalComment && (finalParams['internal-comment'] = decodeURIComponent(internalComment));

    // template for custom css
    urlparams?.template && (finalParams['template'] = decodeURIComponent(urlparams.template));

    // filter-stores
    const filterStores = this.getQueryParamValues(urlparams, 'filter_stores[]', 'filter-stores[]') as string;
    filterStores && (finalParams['filter-stores'] = filterStores.trim());

    // filter-categories
    const filterCategories = this.getQueryParamValues(urlparams, 'filter_categories[]', 'filter-categories[]') as string;
    filterCategories && (finalParams['filter-categories'] = filterCategories.trim());

    // appointment service ids
    let appointmentServiceIds = this.getQueryStringKey('service', queryString, true);
    appointmentServiceIds = appointmentServiceIds ? appointmentServiceIds : [];
    if (appointmentServiceIds.length) {
      finalParams['selected-appointment-service-ids'] = Number(appointmentServiceIds[0]);
    }

    // Appointment service category ids
    let appointmentCategoryIds = this.getQueryStringKey('category', queryString, true);
    appointmentCategoryIds = appointmentCategoryIds ? appointmentCategoryIds : [];
    if (appointmentCategoryIds.length) {
      finalParams['selected-appointment-service-category-ids'] = appointmentCategoryIds[0];
    }

    // Customer data
    let customerData: {
      id: string, prename: string, lastname: string, email: string, mobile: string,
      street: string, gender: string, zip: string, city: string, comment: string
    } = this.getQueryStringKey('customer', queryString, false);
    if (customerData) {
      finalParams['selected-customer'] = customerData;
    }

    // Custom field
    const customFields1 = this.getQueryStringKey('custom_field', queryString, false);
    const customFields2 = this.getQueryStringKey('custom-field', queryString, false);
    if (customFields1) {
      finalParams['selected-custom-fields'] = customFields1;
    } else if (customFields2) {
      finalParams['selected-custom-fields'] = customFields2;
    }

    return finalParams;
  }

  isIframe(): boolean {
    return isPlatformBrowser(this.platformId) ? inIframe() : false;
  }

  postMessageToParentWindow(data: {[key: string]: any}): void {
    if (isPlatformBrowser(this.platformId)) {
      try {
        this.window.parent.postMessage(
          data,
          this.dashboardUrl
        );
        LoggerService.log(`[EMIT_TO_PARNT_WINDOW]`, data);
      } catch (error) {
        LoggerService.warn('[EMIT_TO_PARNT_WINDOW]: Failed to emit event to parent window.');
        LoggerService.error(error);
      }
    }
  }

  private registerSvgIcon(iconName: string, assetPath: string): void {
    this.iconRegistry.addSvgIcon(
      iconName,
      this.sanitizer.bypassSecurityTrustResourceUrl(assetPath)
    );
  }

  registerSvgIcons(): void {
    this.svgIcons.forEach(svgIcon => {
      this.registerSvgIcon(`calio-${svgIcon}-icon`, `${this.deployUrl}/images/icons/${svgIcon}.svg`);
    });
  }

  openWebsite(url: string): void {
    if (isPlatformBrowser(this.platformId)) {
      if (!/^https?:\/\//i.test(url)) {
        url = 'http://' + url;
      }
      this.window.open(url, "_blank");
    }
  }

  checkAppliedFiltersByUrl(): { [key: string]: any } {
    let urlparams: any[] = [];
    let stores: string[] | string = undefined;
    let categories: string[] | string = undefined;

    this.route.queryParamMap.subscribe(params => {
      for (const param of params.keys) {
        let value = params.get(param);
        value = (value === 'NULL' || value === 'null' || value === 'undefined' || value === 'UNDEFINED') ? undefined : value;
        urlparams[param] = value;
      }
    });

    if (urlparams['q']) {
      const decodedBase64String = this.base64decode(urlparams['q']);
      urlparams = decodedBase64String.split('&').reduce(function (q, query) {
        const chunks = query.split('=');
        const key = chunks[0];
        const value = (chunks[1] === 'NULL' || chunks[1] === 'null' || chunks[1] === 'undefined' || chunks[1] === 'UNDEFINED') ? undefined : chunks[1];
        return (q[key] = value, q);
      }, []);
    }

    // stores
    stores = this.getQueryParamValues(urlparams, 'filter_stores[]', 'filter-stores[]') as string;
    stores = stores ? stores.split(',') as string[] : undefined;

    // categories
    categories = this.getQueryParamValues(urlparams, 'filter_categories[]', 'filter-categories[]') as string;
    categories = categories ? categories.split(',') as string[] : undefined;

    return { stores, categories }
  }

  // credits for decoder goes to https://github.com/atk
  base64decode(str: string): string {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output: string = '';

    str = String(str).replace(/=+$/, '');

    if (str.length % 4 == 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }

    for (
      // initialize result and counters
      let bc: number = 0, bs: any, buffer: any, idx: number = 0;
      // get next character
      buffer = str.charAt(idx++);
      // character found in table? initialize bit storage and add its ascii value;
      ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
        // and if not first of each 4 characters,
        // convert the first 8 bits to one ascii character
        bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf(buffer);
    }
    return output;
  }
}
