import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, HostListener, Input, PLATFORM_ID, inject } from '@angular/core';

@Directive({
  selector: 'img[appImgFallback]',
  standalone: true
})
export class ImgFallbackDirective {

  @Input() appImgFallback: string;

  private platformId = inject(PLATFORM_ID);
  private elementRef = inject(ElementRef);

  #isBrowser = false;
  constructor() {
    this.#isBrowser = isPlatformBrowser(this.platformId);
  }

  @HostListener('load') load() {
    const element = this.elementRef.nativeElement as HTMLImageElement;
    this.#isBrowser ? element.classList.remove('cbp-animate-pulse') : element.classList.add('cbp-animate-pulse');
  }

  @HostListener('error') loadFallbackOnError() {
    const element = this.elementRef.nativeElement as HTMLImageElement;
    element.src = this.appImgFallback || '/images/logo/calenso_placeholder.png';
  }
}
